<script>
  import { onMount } from "svelte";
  import Papa from "papaparse";
  import { writable } from "svelte/store";

  let currentPage = 1;
  let totalPages = 1;
  let numberOfPosts = 20; // Default value for the input
  let numberOfPostsGenerated = 100; // Default value for the input
  let owner = ""; // Default value for the input

  let posts = writable([]);
  let jobData = writable([]);
  let isLoading = false; // Define isLoading variable
  let authToken = "";
  let selected = [];
  const baseUrl = "https://fbc-api.cronoks.com/api";
  // const baseUrl = "http://localhost:3000/api";
  let files;
  let totalCount = 0;
  let copiedCount = 0;
  let pendingCount = 0;
  let alertMessage = "";
  let alertType = "alert";
  let pendingJobCount = 10; // Example starting number
  let initialCount = pendingJobCount;
  let token = null;

  //filter objects
  let filterObject = {};
  let sortBy = "createdAt";
  let askOrDesk = -1;
  let paginator = {
    page: 1,
    perPage: 10,
  };

  onMount(() => {
    token = localStorage.getItem("token") || null;
    fetchJobsData();
    fetchData();
    // Example of updating pendingCount
    // Simulate a decrease in pendingCount
  });

  // Reactive statement to update width percentage
  $: widthPercentage = ((initialCount - pendingJobCount) / initialCount) * 100;

  function exportSelectedAsJson() {
    const selectedData = $posts.filter((post) => selected.includes(post._id));
    const selectedIds = selectedData.map((post) => post._id);
    const json = JSON.stringify(
      selectedData.map(({ image, text, link }) => ({
        image,
        message: text,
        link,
      })),
      null,
      2
    );

    exported(selectedIds);

    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.json";
    a.click();
    URL.revokeObjectURL(url);
  }

  function CopySelectedAsJson() {
    const comment =
      "“Help us share this link by liking below if you found it useful. Your support means the world to us! ❤️😊” \nDeal link👉 : \n";
    const selectedData = $posts.filter((post) => selected.includes(post._id));
    const selectedIds = selectedData.map((post) => post._id);
    const json = JSON.stringify(
      selectedData.map(({ image, text, generatedUrl }) => ({
        image: text == "" ? image : "",
        message: text,
        link: generatedUrl,
      })),
      null,
      2
    );

    exported(selectedIds);

    copyPostArea(json);
  }

  function deleteSelectedJson() {
    const selectedData = $posts.filter((post) => selected.includes(post._id));
    const selectedIds = selectedData.map((post) => post._id);

    const confirmed = window.confirm(
      `Are you sure you want to ${selectedIds.length} delete posts?`
    );
    if (confirmed) deletePosts(selectedIds);
  }

  function deletePosts(postIds) {
    fetch(baseUrl + "/post/deletePosts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ postsId: postIds }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to DELETE Posts");
        }
        fetchData();
        return response.json();
      })
      .then((data) => {
        console.log("Posts Deleted successfully:", data);
      })
      .catch((error) => {
        console.error("Error deleting posts:", error);
      });
  }

  function exported(postIds) {
    fetch(baseUrl + "/bot/exported", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ postsId: postIds }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update exported status");
        }
        fetchData();
        return response.json();
      })
      .then((data) => {
        console.log("Export status updated successfully:", data);
      })
      .catch((error) => {
        console.error("Error updating export status:", error);
      });
  }

  function toggleAll(e) {
    selected = e.target.checked ? [...$posts.map((e) => e._id)] : [];
  }

  async function fetchAnalyticsData() {
    try {
      const response = await fetch(baseUrl + "/post/analytics");
      if (response.ok) {
        const data = await response.json();
        totalCount = data.totalCount;
        copiedCount = data.copiedCount;
        pendingCount = data.pendingCount;
        // Update HTML elements
        const publishedCountElement = document.getElementById("totalCount");
        const copiedCountElement = document.getElementById("copiedCount");
        const pendingCountElement = document.getElementById("pendingCount");
        if (publishedCountElement)
          publishedCountElement.textContent = totalCount;
        if (copiedCountElement) copiedCountElement.textContent = copiedCount;
        if (pendingCountElement) pendingCountElement.textContent = pendingCount;
      } else {
        console.error("Failed to fetch analytics data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    }
  }

  async function fetchJobsData() {
    try {
      const response = await fetch(baseUrl + "/bot/export-status");
      if (response.ok) {
        const data = await response.json();
        console.log("fetchJobsData", data);

        jobData.set(data);
        // $jobData = $jobData.map((m) => [...m, (pendingJobCount = m.pending)]);
      }
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    }
  }

  async function fetchData() {
    try {
      updateFilter();
      const response = await fetch(`${baseUrl}/post/getAllPost`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          filter: filterObject,
          sortBy: sortBy,
          askOrDesk: askOrDesk,
          paginator: { page: currentPage, perPage: paginator.perPage }, // Pass the current page and perPage value
        }),
      });

      if (response.ok) {
        fetchAnalyticsData();

        const data = await response.json();
        posts.set(data.data);
        totalPages = data.totalPages;
        currentPage = data.currentPage;
      } else {
        console.error("Failed to fetch data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function handleUpload() {
    if (!files || files.length === 0) {
      console.error("No file selected");
      showAlert("No file selected");
      return;
    }

    // if (!token) {
    //   console.error("Auth token is missing");
    //   showAlert("Auth token is missing");
    //   return;
    // }
    isLoading = true;
    const reader = new FileReader();
    reader.onload = async (e) => {
      const csv = e.target.result;
      const result = Papa.parse(csv, { header: true });
      const newPosts = result.data.map((item, index) => ({
        ...item,
      }));
      try {
        const response = await fetch(baseUrl + "/bot/createAffiliateLink", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ posts: newPosts }),
        });
        if (response.ok) {
          isLoading = false;
          fetchData();
        } else {
          isLoading = false;
          showAlert(response.error, "error");
          console.error("Failed to upload CSV:", response.error);
        }
      } catch (error) {
        isLoading = false;
        showAlert(response.error);
        console.error("Error uploading CSV:", error);
      }
    };
    reader.readAsText(files[0]);
  }

  function nextPage() {
    if (currentPage < totalPages) {
      currentPage++;
      fetchData();
    }
  }

  // Function to fetch the previous page
  function prevPage() {
    if (currentPage > 1) {
      currentPage--;
      fetchData();
    }
  }

  // Function to change the number of rows per page
  function changePerPage(event) {
    paginator.perPage = parseInt(event.target.value, 10);
    currentPage = 1; // Reset to the first page when the per-page limit changes
    fetchData();
  }

  function tokenHandler(isDelete) {
    if (isDelete) {
      localStorage.removeItem("token");
      token = null;
      return;
    }
    token = authToken;
    localStorage.setItem("token", token);
  }

  async function publishTG(_id) {
    try {
      const post = $posts.find((post) => post._id === _id);
      if (!post) {
        console.error("Post not found");
        return;
      }

      const response = await fetch(baseUrl + "/bot/publishTelegram", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ post }),
      });

      if (response.ok) {
        // Show loading indicator
        isLoading = true;
        showAlert("Message published successfully", "success");

        // Simulate delay for 5 seconds
        setTimeout(() => {
          // Update the post status to published
          fetchData(); // Refresh the list of posts
          isLoading = false; // Hide loading indicator
          console.log("Message published successfully");
        }, 5000);
      } else {
        console.error("Failed to publish message:", response.statusText);
        showAlert("Failed to publish message: " + response.statusText, "error");
      }
    } catch (error) {
      console.error("Error publishing message:", error);
      showAlert("Error publishing message: " + error.message, "error");
    }
  }

  async function copyPost(_id, textToCopy) {
    try {
      const response = await fetch(baseUrl + "/bot/copied?id=${_id}", {
        method: "GET",
      });
      if (response.ok) {
        await navigator.clipboard.writeText(textToCopy);
        console.log("Text copied to clipboard:", textToCopy);
        fetchData();
        showAlert("Text copied to clipboard: " + textToCopy, "success");
      } else {
        console.error("Failed to copied:", response.statusText);
        showAlert("Failed to copied: " + response.statusText, "error");
      }
    } catch (error) {
      console.error("Error marking post as copied:", error);
      showAlert("Failed to copied: " + error.message, "error");
    }
  }

  async function copyText(_id, textToCopy) {
    try {
      const response = await fetch(baseUrl + `/bot/copyText?id=${_id}`, {
        method: "GET",
      });
      if (response.ok) {
        await navigator.clipboard.writeText(textToCopy);
        console.log("Text copied to clipboard:", textToCopy);
        fetchData();
        showAlert("Text copied to clipboard: " + textToCopy, "success");
      } else {
        console.error("Failed to copied:", response.statusText);
        showAlert("Failed to copied: " + response.statusText, "error");
      }
    } catch (error) {
      console.error("Error marking post as copied:", error);
      showAlert("Failed to copied: " + error.message, "error");
    }
  }

  async function copyUrl(_id, textToCopy) {
    try {
      const response = await fetch(baseUrl + `/bot/copyUrl?id=${_id}`, {
        method: "GET",
      });
      if (response.ok) {
        await navigator.clipboard.writeText(textToCopy);
        console.log("Text copied to clipboard:", textToCopy);
        fetchData();
        showAlert("Text copied to clipboard: " + textToCopy, "success");
      } else {
        console.error("Failed to copied:", response.statusText);
        showAlert("Failed to copied: " + response.statusText, "error");
      }
    } catch (error) {
      console.error("Error marking post as copied:", error);
      showAlert("Failed to copied: " + error.message, "error");
    }
  }

  async function copyCollector() {
    try {
      // Fetch the content of the file from the public directory
      const response = await fetch("/external/collector.txt");
      if (!response.ok) throw new Error("Failed to fetch file content");

      let data = await response.text();

      // Replace the placeholder with the actual value
      data = data.replace("numberOfPosts", numberOfPosts);

      // Copy the modified content to the clipboard
      await navigator.clipboard.writeText(data);

      showAlert("Script Collector to clipboard ", "success");
    } catch (err) {
      console.error("Failed to copy:", err.message);
      showAlert("Failed to copy: " + err.message, "error");
    }
  }

  async function copyGenerator() {
    try {
      if (!numberOfPostsGenerated || numberOfPostsGenerated === "") {
        console.error("Number of generated post is missing !!");
        showAlert("Number of generated post is missing !!", "error");
        return;
      }

      if (!owner || owner === "") {
        console.error("Owner is missing !!");
        showAlert("Owner missing !!", "error");
        return;
      }

      // Fetch the content of the file from the public directory
      const response = await fetch("/external/generator.txt");
      if (!response.ok) throw new Error("Failed to fetch file content");

      let data = await response.text();

      data = data.replace("{OWNER}", owner);
      data = data.replace("{TAKE}", numberOfPostsGenerated);
      console.log("data", data);

      // Copy the modified content to the clipboard
      await navigator.clipboard.writeText(data);

      showAlert("Script Generator to clipboard ", "success");
    } catch (err) {
      console.error("Failed to copy:", err.message);
      showAlert("Failed to copy: " + err.message, "error");
    }
  }

  async function copyPostArea(textToCopy) {
    try {
      await navigator.clipboard.writeText(textToCopy);
      console.log("Text copied to clipboard:", textToCopy);
      showAlert("Text copied to clipboard: " + textToCopy, "success");
    } catch (error) {
      console.error("Error marking post as copied:", error);
      showAlert("Failed to copied: " + error.message, "error");
    }
  }

  async function copyEvent(id, textToCopy, type) {
    try {
      if (type === "url") {
        await copyUrl(id, textToCopy);
      } else if (type === "text") {
        await copyText(id, textToCopy);
      }
    } catch (error) {
      console.error("Error marking post as copied:", error);
      showAlert("Failed to copied: " + error.message, "error");
    }
  }

  function showAlert(message, type = "alert") {
    alertMessage = message;
    alertType = type;
    setTimeout(() => {
      alertMessage = ""; // Clear the alert message after a certain time
      alertType = null; // Clear the alert message after a certain time
    }, 3000); // Adjust the time as needed
    alertType = "alert";
  }

  function updateFilter(event) {
    const selectedOwner = filterObject?.owner;

    if (selectedOwner === "" || selectedOwner === "all") {
      // If "All" is selected, send an empty filter objec
      filterObject = {};
    } else {
      // Otherwise, filter by the selected owner
      filterObject = { owner: selectedOwner };
    }

    console.log("filterObject", filterObject);
  }

  function downloadZip() {
    const link = document.createElement("a");
    link.href = "/external/PEPE.zip"; // Path to the ZIP file
    link.download = "PEPE.zip"; // Filename to be used for the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function formatReadableDate(dateString) {
    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleString("fr", options);
  }

  function buildName(str) {
    return str != null
      ? str
          .split(" ") // Split the string by spaces to get individual words
          .map((word) => word.charAt(0).toUpperCase()) // Extract the first letter and convert it to uppercase
          .join(".")
      : "--"; // Concatenate the letters into a single string
  }
</script>

<main>
  {#if alertMessage}
    <div class={alertType}>{alertMessage}</div>
  {/if}

  {#if isLoading}
    <div class="loading-overlay">
      <div class="loader"></div>
    </div>
  {/if}

  <h1 class="title">Posts</h1>
  <div class="box-container">
    <div style="width:20% ">
      <div class="job-container">
        <h2 class="title">Download</h2>

        <div class="dashboard-content progress-container">
          <select id="owner" class="form-select" bind:value={owner}>
            <option value="Younes AF">Younes</option>
            <option value="Mourad SB">Mourad</option>
          </select>
          <div>
            <input
              type="number"
              bind:value={numberOfPosts}
              style="width: 50%;"
            />
            <button
              on:click={copyCollector}
              type="button"
              class="btn btn-primary">Collector</button
            >
          </div>
          <div>
            <input
              type="number"
              bind:value={numberOfPostsGenerated}
              style="width: 50%;"
              required
            />
            <button
              on:click={copyGenerator}
              type="button"
              disabled={owner == ""}
              class="btn btn-secondary">Generator</button
            >
          </div>
          <div>
            <button on:click={downloadZip} type="button" class="btn btn-success"
              >PEPE.zip</button
            >
          </div>
        </div>
      </div>

      <div class="job-container">
        <h2 class="title">Jobs</h2>
        <div class="dashboard-content progress-container">
          {#each $jobData as { completed, delay, exportdate, timingLeft, totalPosts }}
            <div
              class="progress-bar"
              style="background-size: {Math.round(
                (completed / totalPosts) * 100
              )}%;"
            >
              <p>
                {completed} /
                <span>{totalPosts}</span>
              </p>
              <p>
                {timingLeft}
                <span> Left</span>
              </p>
            </div>
          {/each}
        </div>
      </div>
    </div>

    <div class="dashboard">
      <div class="dashboard-content">
        <div class="card">
          <p>Pending: <span id="pendingCount">{pendingCount}</span></p>
        </div>
        <div class="card published">
          <p>
            Total: <span id="totalCount">{totalCount}</span>
          </p>
        </div>
        <div class="card copied">
          <p>Copied: <span id="copiedCount">{copiedCount}</span></p>
        </div>
      </div>
      <br />

      <div class="row mb-3">
        <!-- Filter Dropdown -->
        <div class="col">
          <label for="filter" class="form-label">Filter by Owner:</label>
          <select
            id="filter"
            class="form-select"
            bind:value={filterObject.owner}
            on:change={updateFilter}
          >
            <option value="all" selected>All</option>
            <option value="Younes AF">Younes</option>
            <option value="Mourad SB">Mourad</option>
          </select>
        </div>

        <!-- SortBy Dropdown -->
        <div class="col">
          <label for="sortBy" class="form-label">Sort by:</label>
          <select id="sortBy" class="form-select" bind:value={sortBy}>
            <option value="createdAt" selected>Created At</option>
            <option value="exported">Exported</option>
            <option value="copied">Copied</option>
          </select>
        </div>

        <!-- Ask or Desk Dropdown -->
        <div class="col">
          <label for="askOrDesk" class="form-label">Order:</label>
          <select id="askOrDesk" class="form-select" bind:value={askOrDesk}>
            <option value="-1" selected>Desk</option>
            <option value="1">Ask</option>
          </select>
        </div>

        <!-- Button to Fetch Data -->
        <div class="col-auto">
          <button class="btn btn-primary mt-4" on:click={fetchData}
            >Fetch Data</button
          >
        </div>
      </div>

      <div class="upload-container">
        <!-- {#if !token}
          <div class="textarea-container">
            <textarea bind:value={authToken} placeholder="Enter Auth Token"
            ></textarea>
            <i
              class="material-icons"
              style="color: #ee5e5e;"
              on:click={() => tokenHandler(false)}>check</i
            >
          </div>
        {:else}
          <div class="token-container">
            <p>TOKEN ADDED</p>
            <i
              class="material-icons"
              style="color: #ee5e5e; "
              on:click={() => tokenHandler(true)}>delete</i
            >
          </div>
        {/if} -->

        <input type="file" accept=".csv" bind:files class="file-input" />
        <button on:click={handleUpload} disabled={isLoading} class="upload-btn"
          >Import CSV</button
        >
      </div>
      {#if selected.length}
        <button
          on:click={deleteSelectedJson}
          class="btn btn-danger sticky-top p-2"
        >
          Delete <strong>{selected.length}</strong> Posts
        </button>
        <div class="export-container sticky-top p-2">
          <button on:click={exportSelectedAsJson} class="export-btn"
            >Export <strong>{selected.length}</strong> Selected Posts As JSON</button
          >
          <button on:click={CopySelectedAsJson} class="export-btn"
            >Copy <strong>{selected.length}</strong> Selected Posts</button
          >
        </div>
      {/if}

      <div class="table-container">
        <table class="posts-table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  on:change={toggleAll}
                  checked={selected.length === posts.length}
                />
              </th>
              <th>Created At</th>
              <th>Long Url</th>
              <th>Text</th>
              <th>Generated Link</th>
              <th>Owner</th>
              <th>Image</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {#each $posts as { _id, text, longlink, image, generatedUrl, published, copied, postId, createdAt, exported, imageMavely, owner, copiedText, copiedUrl }}
              <tr
                key={_id}
                class:published
                class:copied={copiedText > 0 && copiedUrl > 0}
                class:selected={selected?.includes(_id)}
              >
                <td>
                  <input
                    type="checkbox"
                    bind:group={selected}
                    name={_id}
                    value={_id}
                  />
                </td>
                <!-- Other columns -->
                <td>{formatReadableDate(createdAt)}</td>
                <td class="text-column"
                  ><a href={longlink} target="_blank">{longlink}</a></td
                >
                <td
                  on:click={() => copyEvent(_id, text, "text")}
                  class="text-column selectedArea"
                  class:copied={copiedText > 0}
                  title={text}>{text}</td
                >
                <td
                  on:click={() => copyEvent(_id, generatedUrl, "url")}
                  class="selectedArea"
                  class:copied={copiedUrl > 0}
                  ><a href={generatedUrl} target="_blank">{generatedUrl}</a></td
                >
                <td>
                  <h2 class="text-center mx-auto my-auto" alt={owner}>
                    {buildName(owner)}
                  </h2>
                </td>
                <td
                  >{#if image}
                    <img
                      on:click={() => copyPostArea(image)}
                      class="selectedArea post-image"
                      width="50px"
                      height="50px"
                      src={image}
                      alt="Post Image"
                    />
                  {:else}
                    <img
                      width="50px"
                      height="50px"
                      src={imageMavely}
                      alt="Default Image"
                      class="post-image"
                    />
                  {/if}</td
                >
                <td>
                  <div
                    style="display: flex;
              gap: 1rem;
              justify-content: space-around;
               width:100%"
                  >
                    {#if !published}
                      <div
                        on:click={() => publishTG(_id)}
                        style="cursor: pointer;"
                        class:disabled={isLoading}
                      >
                        <i class="material-icons" style="color: #24A1DE;"
                          >send</i
                        >
                      </div>
                    {/if}
                    <div>
                      <p style="color: #24A1DE;">{exported ?? 0}</p>
                    </div>
                  </div>
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      </div>

      <div class="pagination">
        <select bind:value={paginator.perPage} on:change={changePerPage}>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        <span>rows per page</span>

        <button on:click={prevPage} disabled={currentPage === 1}>
          Previous
        </button>

        <span>{currentPage} / {totalPages}</span>

        <button on:click={nextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
  </div>
</main>

<style>
  .textarea-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .textarea-container > i {
    cursor: pointer;
  }
  .token-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .token-container > i {
    cursor: pointer;
  }
  .pagination {
    margin-top: 20px;
    text-align: center;
  }

  .title {
    text-align: center;
    margin-bottom: 20px;
  }

  .upload-container {
    text-align: center;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .upload-btn {
    background-color: #4caf50; /* Green */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }

  .upload-btn:hover {
    background-color: #127117;
  }

  .posts-table {
    border-collapse: collapse;
    width: 100%;
  }

  .posts-table th,
  .posts-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  .posts-table th {
    background-color: #f2f2f2;
  }

  .post-image {
    max-width: 100px;
    max-height: 100px;
  }
  .text-column {
    max-width: 150px; /* Adjust the max-width as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .table-container {
    position: relative;
    margin-bottom: 20px; /* Add margin bottom to create space for the button */
  }

  .selectedArea {
    cursor: pointer; /* Show pointer cursor on hover to indicate clickability */
  }

  .selectedArea:hover {
    background-color: rgba(47, 255, 106, 0.145);
  }

  .published {
    background-color: rgba(68, 47, 255, 0.145) !important;
  }
  .copied {
    background-color: rgba(47, 255, 106, 0.145) !important;
  }

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* Style for disabled button */
  button[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .dashboard {
    width: 75%;
    margin: auto;
    min-height: 100%;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .job-container {
    margin: auto;
    margin-bottom: 10%;
    min-height: 100%;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .dashboard h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }

  .dashboard-content {
    display: flex;
    justify-content: space-between;
  }

  .card {
    flex: 1;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-right: 20px;
  }

  .card p {
    font-size: 16px;
    color: #666;
  }

  .card span {
    font-weight: bold;
    color: #333;
  }
  .alert {
    background-color: rgba(47, 255, 106, 0.145) !important; /* Red background */
    color: #333; /* White text */
    padding: 15px; /* Padding around the text */
    margin-bottom: 20px; /* Margin at the bottom to separate from other elements */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  }
  .error {
    background-color: rgba(255, 47, 47, 0.145) !important; /* Red background */
    color: #333; /* White text */
    padding: 15px; /* Padding around the text */
    margin-bottom: 20px; /* Margin at the bottom to separate from other elements */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  }
  .upload-btn[disabled] {
    background-color: #ccc; /* Example: greyed-out background */
    cursor: not-allowed;
  }
  .export-container {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 1rem;
  }
  .export-container button {
    appearance: button;
    backface-visibility: hidden;
    background-color: #405cf5;
    border-radius: 6px;
    border-width: 0;
    box-shadow:
      rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
      rgba(50, 50, 93, 0.1) 0 2px 5px 0,
      rgba(0, 0, 0, 0.07) 0 1px 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
      Ubuntu, sans-serif;
    font-size: 100%;
    height: 44px;
    line-height: 1.15;
    outline: none;
    overflow: hidden;
    padding: 0 25px;
    position: relative;
    text-align: center;
    text-transform: none;
    transform: translateZ(0);
    transition:
      all 0.2s,
      box-shadow 0.08s ease-in;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .export-container button:focus {
    box-shadow:
      rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
      rgba(50, 50, 93, 0.2) 0 6px 15px 0,
      rgba(0, 0, 0, 0.1) 0 2px 2px 0,
      rgba(50, 151, 211, 0.3) 0 0 0 4px;
  }

  .job {
    margin: 5px;
    border: 1px solid #ddd;
    background-color: white;
    transition: background-color 0.3s ease-in-out;
    flex: 1;
    border-radius: 8px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin-right: 20px;
    position: relative;
    overflow: hidden; /* To clip the loading bar */
  }

  .progress-container {
    background-color: #f3f3f3;
    border-radius: 5px;
    /* margin: 30px; */
    /* height: 20px; */
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 1rem;
  }

  .progress-bar {
    width: 100%;
    text-align: center;
    height: 100%;
    background-color: pink;
    border: 1px solid rgb(247, 90, 116);
    border-radius: 15px;
  }

  .box-container {
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 1rem;
  }
</style>
