<!-- frontend/my-svelte-app/src/components/GroupCounts.svelte -->
<script>
  import { writable } from "svelte/store";
  const baseUrl = "https://fbc-api.cronoks.com/api";
  //const baseUrl = "http://localhost:3000/api";

  let allLists = writable([]);
  let take = writable(100);

  async function getAllLists(groupName, count) {
    try {
      const response = await fetch(baseUrl + "/member/getAllLists/${count}", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ groupName }),
      });

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching all lists:", error.message);
      return null;
    }
  }

  async function fetchData() {
    try {
      const response = await fetch(baseUrl + "/member/getAllLists");
      const data = await response.json();
      allLists.set(data);
    } catch (error) {
      console.error("Error fetching all lists:", error.message);
    }
  }
  async function setHistory(groupName, count, members) {
    try {
      const body = JSON.stringify({ groupName, count, members });
      await fetch(baseUrl + "/member/set-history", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      });
    } catch (error) {
      console.error("Error fetching all lists:", error.message);
    }
  }
  fetchData();
  async function downloadTxtFile(groupName, count) {
    const res = await getAllLists(groupName, count);
    if (res) {
      const memberIds = res.members.join("\n");
      const blob = new Blob([memberIds], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${res.groupName}.txt`;
      document.body.appendChild(a);
      a.click();
      await setHistory(groupName, count, res.members);
      document.body.removeChild(a);
      location.reload();
    }
  }
</script>

<h1>Groups</h1>

<table>
  <thead>
    <tr>
      <th>Group Name</th>
      <th>Total</th>
      <th>
        <input type="number" id="take" bind:value={$take} />
      </th>
    </tr>
  </thead>
  <tbody>
    {#each $allLists as group}
      <tr key={group.groupName}>
        <td>{group.groupName}</td>
        <td>{group.countNonCopied} / {group.countTotal}</td>
        <td>
          <button
            on:click={() =>
              downloadTxtFile(
                group.groupName,
                group.countNonCopied > $take ? $take : group.countNonCopied
              )}
            disabled={group.countNonCopied == 0}
          >
            Download Member IDs
          </button>
        </td>
      </tr>
    {/each}
  </tbody>
</table>

<style>
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }
</style>
