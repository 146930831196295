<script>
  import { onMount } from "svelte";
  import Chart from "chart.js/auto";
  const baseUrl = "https://fbc-api.cronoks.com/api";
  // const baseUrl = "http://localhost:3000/api";

  // Static values for the dashboard
  let totalPosts = 0;
  let totalNonGeneratedPosts = 0;
  let totalGeneratedPosts = 0;
  let publishedPosts = 0;
  let commissionEstimation = 0;
  let postsPerDay = []; // Array to hold posts data per day

  // Function to fetch dashboard data
  async function fetchDashboardData() {
    try {
      const response = await fetch(baseUrl + "/dashboard"); // Adjust the API endpoint as necessary
      if (response.ok) {
        const data = await response.json();
        totalPosts = data.totalPosts;
        totalNonGeneratedPosts = data.totalNonGeneratedPosts;
        totalGeneratedPosts = data.totalGeneratedPosts;
        publishedPosts = data.totalPublishedPosts; // Assuming your API returns this field
        commissionEstimation = data.commissionEstimation;
        postsPerDay = data.postsData.map((post) => post.count); // Extract counts for the chart
      } else {
        console.error("Failed to fetch dashboard data");
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  }

  // Create chart after data is fetched
  let chart;
  onMount(() => {
    fetchDashboardData();

    const ctx = document.getElementById("postsChart");
    chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
          {
            label: "Number of Posts",
            data: postsPerDay,
            borderColor: "rgba(75, 192, 192, 1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  });

  // Update chart when postsPerDay changes
  $: if (chart) {
    chart.data.datasets[0].data = postsPerDay;
    chart.update();
  }
</script>

<div class="dashboard">
  <h2>Dashboard</h2>

  <div class="row">
    <div class="col-md-3">
      <div class="card stat-card total-posts">
        <div class="card-body">
          <h5 class="card-title">Total Posts</h5>
          <p class="card-text">{totalPosts}</p>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card stat-card total-non-generated">
        <div class="card-body">
          <h5 class="card-title">Total Non-Generated Posts</h5>
          <p class="card-text">{totalNonGeneratedPosts}</p>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card stat-card total-generated">
        <div class="card-body">
          <h5 class="card-title">Total Generated Posts</h5>
          <p class="card-text">{totalGeneratedPosts}</p>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card stat-card published-posts">
        <div class="card-body">
          <h5 class="card-title">Published Posts</h5>
          <p class="card-text">{publishedPosts}</p>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card stat-card commission-estimation">
        <div class="card-body">
          <h5 class="card-title">Commission Estimation</h5>
          <p class="card-text">{commissionEstimation} DH</p>
        </div>
      </div>
    </div>
  </div>

  <h4>Posts per Day</h4>
  <canvas id="postsChart"></canvas>
</div>

<style>
  .dashboard {
    padding: 20px;
  }

  .stat-card {
    margin: 10px; /* Add margin for spacing between cards */
    color: #000; /* Set text color to black for contrast */
  }

  .total-posts {
    background-color: #d2e0fb; /* Light Blue */
  }

  .total-non-generated {
    background-color: #fef9d9; /* Light Yellow */
  }

  .total-generated {
    background-color: #dee5d4; /* Light Green */
  }

  .published-posts {
    background-color: #8eaccd; /* Light Cyan */
  }

  .commission-estimation {
    background-color: #d2e0fb; /* Reusing Light Blue */
  }
</style>
