<script>
  import { readable } from "svelte/store";
  // const baseUrl = "http://localhost:3000/api";
  const baseUrl = "https://fbc-api.cronoks.com/api";

  let history = readable([], (set) => {
    fetch(baseUrl + "/member/get-history")
      .then((response) => response.json())
      .then((data) => {
        set(data);
      })
      .catch((err) => {
        set([]);
      });
  });

  async function downloadHistory(historyId, groupName) {
    const memberIds = await fetch(`/api/get-history/${historyId}`).then(
      (response) => response.json()
    );
    const historyData = memberIds.map((item) => item.members.join("\n"));
    const blob = new Blob([historyData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${groupName}.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  function formatReadableDate(dateString) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toDateString();
  }
</script>

<h1>History</h1>

<table>
  <thead>
    <tr>
      <th>Group Name</th>
      <th>Count</th>
      <th>Created At</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    {#each $history as { _id, groupName, count, createdAt }}
      <tr key={_id}>
        <td>{groupName}</td>
        <td>{count}</td>
        <td>{formatReadableDate(createdAt)}</td>
        <td
          ><button on:click={downloadHistory(_id, groupName)}
            >Download Member IDs</button
          >
        </td>
      </tr>
    {/each}
  </tbody>
</table>

<style>
  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }
</style>
