<!-- App.svelte -->
<script>
  import { Router, Route, Link } from "svelte-routing";
  import Index from "./components/Index.svelte"; // Import the Index component
  import Posts from "./components/Posts.svelte"; // Import the Posts component
  import Dashboard from "./components/Dashboard.svelte";
</script>

<Router>
  <Route path="/" component={Index} />
  <Route path="/posts" component={Posts} />
  <Route path="/dash" component={Dashboard} />
  <!-- Uncommented Posts route -->
  <!-- Define more routes here if needed -->
</Router>

<main></main>

<style>
</style>
