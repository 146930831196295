<!-- Index.svelte -->
<script>
  import { Router, Route, Link } from "svelte-routing";
  import Posts from "./Posts.svelte";
  import GroupCounts from "./GroupCount.svelte";
  import History from "./History.svelte";
  import { navigate } from "svelte-routing";
  const baseUrl = "https://fbc-api.cronoks.com/api";
  // const baseUrl = "http://localhost:3000/api";

  let isLoading = false;
  let errorMessage = "";

  async function removeDuplicates() {
    try {
      isLoading = true;
      const formData = new FormData();
      const file = document.getElementById("csvFileInput").files[0];

      if (file && file.type !== "text/csv") {
        errorMessage = "Only .csv files are accepted for Upload Report.";
        return;
      }

      formData.append("file", file);

      const response = await fetch(baseUrl + "/bot/removeDuplicates");

      const data = await response.json();
      console.log("data", data);
    } catch (error) {
      console.error("Error removing duplicates:", error.message);
    } finally {
      isLoading = false;
    }
  }

  const handleFileChange = async (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    const filename = file.name.split(".")[0]; // Get filename without extension

    if (file && file.type !== "text/plain") {
      errorMessage = "Only .txt files are accepted for Upload Members.";
      return;
    }

    if (file) {
      let members = [];
      const content = await readFileContent(file);
      members = content.split("\n").map((line) => line.trim());
      await addList(filename, members);
      location.reload();
    }
  };

  // const readFileContent = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();

  //     reader.onload = (e) => {
  //       resolve(e.target.result);
  //     };

  //     reader.onerror = (e) => {
  //       reject(e.target.error);
  //     };

  //     reader.readAsText(file);
  //   });
  // };

  async function addList(groupName, members) {
    try {
      isLoading = true;
      console.log("groupName", groupName);
      console.log("members", members[1]);

      const response = await fetch(baseUrl + "/member/addList", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ groupName, members }),
      });

      const data = await response.json();
      console.log("data", data);
      return data;
    } catch (error) {
      console.error("Error fetching all lists:", error.message);
      return null;
    } finally {
      isLoading = false;
    }
  }

  const handleReportClick = async () => {
    const reportFileInput = document.createElement("input");
    reportFileInput.type = "file";
    reportFileInput.accept = ".csv"; // Correct the accepted file type to .csv

    reportFileInput.onchange = async (event) => {
      const file = event.target.files[0];

      if (file) {
        if (file.type !== "text/csv") {
          alert("Only .csv files are accepted for upload.");
          return;
        }

        try {
          const content = await readFileContent(file);
          const lines = content
            .split("\n")
            .filter((line) => line.trim() !== "");

          if (lines.length === 0) {
            alert("The file is empty.");
            return;
          }

          const header = lines[0].split(",").map((h) => h.trim());
          const data = lines.slice(1).map((line) => {
            const fields = line.split(",");
            const entry = {};
            header.forEach((key, index) => {
              entry[key] = fields[index] ? fields[index].trim() : null;
            });
            return entry;
          });

          // Filter objects where Status is 'FAILED'
          const failedEntries = data.filter(
            (entry) => entry.Status === "FAILED"
          );

          // Extract TargetUserId from failed entries
          const failedUserIds = failedEntries.map(
            (entry) => entry.TargetUserId
          );

          console.log(failedUserIds); // Log the failed TargetUserId values

          // Create a blob with failedUserIds and export as a .txt file
          const blob = new Blob([failedUserIds.join("\n")], {
            type: "text/plain",
          });
          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = `failed_user_ids_${failedUserIds.length}.txt`;
          downloadLink.click();
          // Example call to addList function, uncomment and modify as needed
          // await addList(file.name.split(".")[0], failedUserIds); // Use filename without extension as groupName
          location.reload();
        } catch (error) {
          console.error("Error reading file:", error);
          alert("An error occurred while processing the file.");
        }
      }
    };

    reportFileInput.click();
  };

  // Function to read file content
  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };

  let txtFileInput;

  const triggerFileInput = () => {
    txtFileInput.click();
  };

  const redirectToPosts = () => {
    navigate("/posts");
  };
</script>

<Router>
  <!-- <Route path="/" /> -->
  <!-- <Route path="/" component={Index} /> -->
  <Route path="/posts" component={Posts} />
  <!-- Define more routes here if needed -->
</Router>

<main>
  <h1>FB Collector App</h1>
  <div>
    <input
      type="file"
      accept=".txt"
      style="display: none;"
      bind:this={txtFileInput}
      on:change={handleFileChange}
    />
    <button on:click={triggerFileInput}>Upload Members</button>
    <button on:click={handleReportClick} style="background-color: #FF9800;"
      >Upload Report</button
    >
    <button on:click={redirectToPosts} style="background-color: #4a4e69;"
      >Go to Posts</button
    >

    <!-- <input
      type="file"
      id="txtFileInput"
      accept=".txt"
      on:change={handleFileChange}
      style="display: none;"
    /> -->
    <input
      type="file"
      id="csvFileInput"
      accept=".csv"
      on:change={removeDuplicates}
      style="display: none;"
    />

    {#if errorMessage}
      <p style="color: red;">{errorMessage}</p>
    {:else if isLoading}
      <p>Loading...</p>
    {:else}
      <GroupCounts />
      <History />
    {/if}
  </div>
</main>

<style>
  main {
    text-align: center;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  h1 {
    color: #333;
  }

  div {
    margin-top: 20px;
  }

  button {
    padding: 10px;
    font-size: 16px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  button:hover {
    background-color: #2980b9;
  }

  input[type="file"] {
    display: none;
  }

  p {
    font-size: 18px;
    color: #555;
    margin-top: 10px;
  }
</style>
